<template>
  <div class="app flex-row align-items-center" style="margin-bottom: 25px">
    <div style="margin: auto">
      <clip-loader :loading="loading" color="#20a8d8" :height="200" :width="200"></clip-loader>
    </div>
    <div class="container" v-if="!loading">
      <banner-upload
        :type="parseInt(type)"
        :banners="banners"
        @bannersListUpdated="bannersListUpdated"
        @removedBannersListUpdated="removedBannersListUpdated"
      />
      <b-row>
        <b-col sm="12" md="6" lg="4">
          <b-button
            type="submit"
            block
            variant="primary"
            @click.prevent="submit"
          >
            {{ $t('save')  }}
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import BannerUpload from "../../components/BannerUpload";
  import {APIService} from "../../services/api";
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
  import i18n from "@/plugins/i18n";

  export default {
    components: {
      BannerUpload,
      ClipLoader,
    },
    name: 'BannersList',
    props: {
      type: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        banners: [],
        removedBanners: [],
        subtype: null,
        id: null,
        loading: false,
      }
    },
    watch: {
      type: {
        immediate: true,
        deep: true,
        async handler() {
          await this.loadBanners();
        }
      }
    },
    async beforeMount() {
      this.subtype = 0;
      await this.loadBanners();
    },
    methods: {
      async loadBanners() {
        this.loading = true;
        try {
          const banners = await APIService.get(`banner?sort=orderKey,ASC&join=image&filter=type||$eq||${this.type}`);
          const bannersPromises = banners.map(async(banner) => {
            banner.images = await APIService.get(`image/${banner.id}/Banner`);
            return banner;
          });
          this.banners = await Promise.all(bannersPromises);
          this.removedBanners = [];
        } catch (e) {
          console.error(e.toString());
        }
        this.loading = false;
      },
      bannersListUpdated (banners) {
        this.banners = banners;
      },
      removedBannersListUpdated (banners) {
        this.removedBanners = this.removedBanners.concat(banners);
      },
      async submit() {
        try {
          for (const banner of this.banners) {
            if (banner.id) {
              await APIService.patch(APIService.getRestFullEntityUrl('banner', banner), banner)
            } else {
              if (banner.images && banner.images.length > 0) {
                await APIService.post('banner', banner);
              } else {
                console.log('Error on banner image save', banner);
                throw new Error('Image is mission on banner');
              }
            }
          }
          for (const banner of this.removedBanners) {
            await APIService.delete(APIService.getRestFullEntityUrl('banner', banner), banner);
          }
          this.$eventBus.$emit('alert', {
            type: 'success',
            text: i18n.t('dataWasSuccessfullySaved'),
          });
          window.scrollTo(0,0);
          await this.loadBanners();
        } catch (e) {
          console.error(e);
          this.$eventBus.$emit('alert', {
            type: 'danger',
            text: i18n.t('errorOnDataSaving'),
          });
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-spinner{
    text-align: center;
    .v-clip {
      margin: auto;
    }
  }
</style>
