<template>
  <b-row>
    <b-col sm="12">
      <b-row>
        <draggable
          :list="banners"
          ghost-class="ghost"
          @start="dragging = true"
          @end="dragging = false"
          @update="()=>updateBannersList(banners)"
          style="margin: auto"
        >
          <b-col xs="12" sm="12" md="12" lg="12" v-for="(banner, i) in banners" :key="banner.id">
            <div class="image-container" style="max-width: 600px; margin: auto;">
              <b-img :src="getImageUrl(banner, suffix)"/>
              <span @click="removeBanner(i)">x</span>
              <b-input name="title[]" placeholder="title" v-model="banner.title"/>
              <b-input name="link[]" placeholder="link" v-model="banner.link"/>
            </div>
          </b-col>
        </draggable>

      </b-row>
    </b-col>
    <b-col sm="12" class="mb-3">
      <vue-core-image-upload
        class="btn btn-primary block"
        :text="$t('uploadPhoto')"
        :crop="false"
        @imageuploaded="imageUploaded"
        :data="data"
        :multiple="true"
        :max-file-size="5242880"
        :url="uploadUrl">
      </vue-core-image-upload>
    </b-col>
  </b-row>
</template>

<script>
  import VueCoreImageUpload from 'vue-core-image-upload';
  import draggable from "vuedraggable";
  import {APIService} from "../services/api";

  export default {
    name: 'BannerUpload',
    components: {
      'vue-core-image-upload': VueCoreImageUpload,
      draggable,
    },
    props: {
      banners: {
        type: Array,
        required: true,
      },
      id: {
        type: Number,
        required: false,
      },
      type: {
        type: Number,
        required: false,
        default: 0,
      },
      suffix: {
        type: String,
        required: false,
        default: 'original',
      },
    },
    data: () => {
      return {
        uploadUrl: '',
        bannersList: [],
        dragging: false,
        data: {
          type: 'Banner',
          subtype: null,
        }
      }
    },
    async beforeMount() {
      const id = Math.floor(1000 + Math.random() * 9000);
      this.uploadUrl = APIService.getBaseUrl() + 'image/upload';
      this.data = {
        id,
        type: 'Banner',
        subtype: this.type,
      };
    },
    methods : {
      imageUploaded: function(res) {
        const banners = [];
        res.forEach(image => {
          banners.push({
            title: '',
            link: '',
            images: [image],
          });
        });
        const newList = this.banners.concat(banners).map((banner, i) => {
          banner.orderKey = i + 1;
          return banner;
        });
        this.updateBannersList(newList);
        this.$emit('bannersListUpdated', newList);
      },
      getImageUrl(banner, suffix) {
        if (banner) {
          const image = banner.images && banner.images.length > 0 ? banner.images[0] : null;
          if (!image) {
            return '';
          }
          const imageModificationData = image.modifications.filter(modificationData => modificationData.suffix === suffix);
          if (imageModificationData.length === 0) {
            return '';
          }
          return imageModificationData[0].fileUrl;
        }
        return '';
      },
      updateBannersList(banners) {
        console.log('banner before: ', banners);
        banners = banners.map((banner, i) => {
          if (banner.images && banner.images.length > 0) {
            banner.images[0].orderKey = i + 1;
            banner.images[0].ownerType = 'Banner';
          }
          banner.orderKey = i + 1;
          banner.type = parseInt(this.type);
          return banner;
        })
        console.log('banners: ', banners);
        this.bannersList = banners;
      },
      removeBanner(index) {
        const banners = this.banners;
        const removedBanner = banners[index];
        if (removedBanner) {
          this.$emit('removedBannersListUpdated', [removedBanner]);
        }
        banners.splice(index, 1);
        this.updateBannersList(banners);
      },
    }
  }
</script>

<style lang="scss" scoped>
  .image-container{
    text-align: center;
    position: relative;
    padding-top: 1rem;
    span {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
      color: #20a8d8;
      font-size: 16px;
      font-weight: bold;
    }
    input {
      margin: 5px 0px;
    }
  }
</style>
